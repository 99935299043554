import React from 'react'
import IntegrationTemplate from '../../components/Integrations/IntegrationTemplate'

import googleTagManagerLogo from '../../images/integration-logo-images/googletagmanager.png'

const GoogleTagManager = () => {
    return (
        <>
            <IntegrationTemplate 

                metaTitle="Integrate Optinly with Google Tag Manager"
                metaDescription="Collect leads with Optinly inside your website and analyze it with Google Tag Manager to identify rich prospects."

                otherProductLogo={googleTagManagerLogo} //PLEASE IMPORT THE LOGO HERE LIKE THIS

                integrationApp="Google Tag Manager"
                homeTitle="Grow Your Email List with Google Tag Manager Popups"
                homeDescription="Integrate Optinly with your Google Tag Manager store. Ideal lead generation tool with visitor engaging popups to grow your email list. Increase Sales with smart popups. "

                docsTitle="Increase your Google Tag Manager store email list "
                docsDescription="Best Google Tag Manager popup app. A complete package to rapidly grow your email list, leads and subscribers. Select from pre-made popups or custom popup in your Google Tag Manager store. "

                featruesDocsSection1="Integeration and Customization "
                featruesDocsTitle1="Seamless Integeration and Advance Customization Options"
                featruesDocsDescription1="Customize popups based on user behavioural pattern. Design or development skill not required to customize popup. Google Tag Manager email popup through simple integeration. "

                featruesDocsSection2="Installation and Signup"
                featruesDocsTitle2 ="Google Tag Manager Popups To Improve Store Performance"
                featruesDocsDescription2="Hassle-free signup, download, and installation. Best Google Tag Manager popup form to deliver customer-friendly popup. Select the best email popup for Google Tag Manager from numerous existing templates."

                featruesDocsSection3="Achieve Marketing Goals "
                featruesDocsTitle3 ="One-stop Solution for Google Tag Manager Popup building"
                featruesDocsDescription3="Everything you need to create user engaging popups in one place. Advacned dashboard, simple integeration, regular update of pre-made templates and a clear user interface."

                featruesDocsSection4="Detailed Dashboard Analytics"
                featruesDocsTitle4 ="All-in-one Dashboard"
                featruesDocsDescription4="Know how your Google Tag Manager Popup is performing. Manage campaigns, make changes, and more with a powerful dashboard. Simple to navigate yet highly accurate analytics available."

                FAQQuestion1="Does Optinly offer Google Tag Manager email popup?  "
                FAQAnswer1="Optinly offers multiple redaymade Google Tag Manager email popups. Also, it provides options to easily customize the popup depending upon the user. Google Tag Manager email popups are popups used in Google Tag Manager websites for collecting quality customer email address. "

                FAQQuestion2="How many types of Google Tag Manager popup form is available in Optinly?"
                FAQAnswer2="Optinly offers 6+ types of unique popup forms for Google Tag Manager. Each one is engaging and grabs audience attentation without any delay. "

                FAQQuestion3="Is there a free pricing plan available in Optinly for Google Tag Manager stores? " 
                FAQAnswer3="Optinly have a free pricing plan that works on Google Tag Manager stores. Feel free to check out the entire pricing structure in Optinly pricing page.  "

                FAQQuestion4="Is Google Tag Manager exit popup available in Optinly? "
                FAQAnswer4="Yes, Optinly have customer engaging exit popup templates. You can personalize the template or made use of the pre-made one. "
            />
        </>
    )
}

export default GoogleTagManager
